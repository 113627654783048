
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.resource {
  &-field__element {
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0;
    }
    &-row {
      margin: 0 0 20px 0;
      &:last-child {
        margin: 0;
      }
    }
  }
}
